<template>
  <div class="tingXiaLDetail">
    <div class="title">{{ postData.topicTitle }}</div>
    <div class="tab">
      <div @click="Selection(1)" :class="['tab-l', { active: oIndex == 1 }]">
        社群介绍
      </div>
      <div @click="Selection(2)" :class="['tab-r', { active: oIndex == 2 }]">
        亮点成效
      </div>
    </div>
    <div class="main">
      <v-h5Mtml :content="content"></v-h5Mtml>
    </div>
    <div class="bottom">
      <div class="bottom-l">{{ postData.createTime }}</div>
      <div class="bottom-r">
        <div class="left-wrap">
          <div class="iconText">
            <div class="icon">
              <img src="../img/icon-read.png" />
            </div>
            <div class="text">
              <span>{{ postData.readCount }}</span>
            </div>
          </div>
          <div class="iconText"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTopicInfoByIdURL } from "../api.js";
import { getHashParam } from "@/utils/utils.js";
import { gloabalCount, toRegister } from "@/utils/common";
export default {
  name: "tingXiaLDetail",
  data() {
    return {
      id: "",
      oIndex: 1,
      content: "",
      postData: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    const { id } = this.$route.query;
    this.id = id;
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    Selection(data) {
      this.oIndex = data;
      if (this.oIndex == 1) {
        this.content = this.postData.topicContent;
      } else {
        this.content = this.postData.otherRichText;
      }
    },
    async getInfo(id) {
      let params = {
        id: this.id,
        userId: this.userId,
      };
      let res = await this.$axios.get(`${getTopicInfoByIdURL}`, {
        params: params,
      });
      if (res.code == 200) {
        this.postData = res.data;
        this.content = this.postData.topicContent;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tingXiaLDetail {
  min-height: 100vh;
  padding: 18px 32px;
  .title {
    font-weight: 700;
    font-size: 48px;
    color: #000000;
    line-height: 66px;
  }
  .tab {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 2px 0 52px;
    .tab-l,
    .tab-r {
      flex: 1;
      text-align: center;
      position: relative;
      padding: 26px 0;
      color: rgba(50, 51, 52, 0.5);
    }
    .active {
      position: relative;
      color: #323334;
      &::after {
        content: "";
        position: absolute;
        width: 64px;
        height: 8px;
        background: #fb8133;
        border-radius: 4px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 30px;
    .bottom-l {
      font-weight: 400;
      font-size: 24px;
      color: #aaaaaa;
      line-height: 34px;
    }
    .left-wrap {
      display: flex;
      align-items: center;
      .iconTag {
        flex: 1;
        overflow: hidden;
        margin-right: 8px;
        display: flex;
        align-items: center;
        > span {
          height: 32px;
          display: flex;
          align-items: center;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          border-radius: 4px;
          font-size: 24px;
          background: #fff3eb;
          border-radius: 8px;
          border: 2px solid #ffc7a4;
          padding: 0px 8px;
          color: #fe6f16;
        }
      }
      .iconText {
        display: flex;
        color: #a8a8a8;
        align-items: center;
        &:not(:last-child) {
          margin-right: 32px;
        }
        .icon {
          display: flex;
          align-items: center;
          &.icon-up {
            > img {
              margin-top: 4px;
            }
          }
          > img {
            width: 36px;
            height: 36px;
          }
        }
        .text {
          margin-left: 8px;
          display: flex;
          align-items: center;
          height: 36px;
          line-height: 36px;
          span {
            &.hasPraised {
              color: #ff853a;
            }
            font-size: 24px;
            line-height: 1;
          }
        }
      }
    }
  }
}
</style>
